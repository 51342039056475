<!--
 * @Author: 候怀烨
 * @Date: 2021-03-04 14:56:10
 * @LastEditTime: 2021-04-21 15:33:09
 * @LastEditors: Please set LastEditors
 * @Description: 运营统计
 * @FilePath: \sd-vue-admin\src\views\project\marketing\statistics\operation\index.vue
-->
<!--  -->
<template>
  <div class="orderTest-container">
    <el-row :gutter="10">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="grid-content">
          <h3>网销数据</h3>
          <div class="data">
            <p>
              <span>新开通网销：{{ Onlinedata.new_count }}</span>
              <span>网销订单数量：{{ Onlinedata.order_count }}</span>
            </p>
            <p>
              <span>开通网销客户量：{{ Onlinedata.all_count }}</span>
              <span>网销订单金额：{{ Onlinedata.amount }}</span>
            </p>
          </div>
          <div id="myechart" style="width: 100%; height: 400px"></div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="grid-content">
          <h3>访问统计</h3>
          <div></div>
          <div>
            <el-row>
              <el-col :span="12">
                <div class="Rank">
                  <el-button size="mini" @click="handlerdesc">前十名</el-button>
                  <span>商品排名</span>
                  <el-button size="mini" @click="handlerasc">后十名</el-button>
                </div>
                <el-table stripe :data="tbaleData" height="500px">
                  <el-table-column
                    type="index"
                    align="center"
                    label="排名"
                  ></el-table-column>
                  <el-table-column
                    v-for="(list, index) in colemd"
                    :key="index"
                    :label="list.label"
                    :align="list.align"
                    :prop="list.prop"
                    width=""
                  ></el-table-column>
                </el-table>
              </el-col>
              <el-col :span="12">
                <div class="Rank">
                  <el-button size="mini" @click="handlerdesc1">
                    前十名
                  </el-button>
                  <span>终端排名</span>
                  <el-button size="mini" @click="handlerasc1">后十名</el-button>
                </div>
                <el-table stripe :data="tbaleData1" height="500px">
                  <el-table-column
                    type="index"
                    align="center"
                    label="排名"
                  ></el-table-column>
                  <el-table-column
                    v-for="(list, index) in colemd1"
                    :key="index"
                    :label="list.label"
                    :align="list.align"
                    :prop="list.prop"
                    width=""
                  ></el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        Onlinedata: {
          all_count: '0',
          amount: '0',
          new_count: '0',
          order_count: '0',
        },
        url: {
          data: '/shopAdmin/operate/data',
          trend: '/shopAdmin/operate/sale-trend',
          goods: '/shopAdmin/operate/goods-rank',
          cust: '/shopAdmin/operate/cust-rank',
        },
        tbaleData: [],
        tbaleData1: [],
        colemd: [
          {
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '浏览量',
            align: 'center',
            prop: 'views',
            width: '',
          },
          {
            label: '销量',
            align: 'center',
            prop: 'count',
            width: '',
          },
        ],
        colemd1: [
          {
            label: '终端名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '电话',
            align: 'center',
            prop: 'mobile',
            width: '',
          },
          {
            label: '销量',
            align: 'center',
            prop: 'count',
            width: '',
          },
        ],
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.handleronlinedata()
      this.handlertrend()
      this.handlerdesc()
      this.handlerdesc1()
    },
    methods: {
      // desc:前十，asc：后十
      handlerdesc() {
        this.handlertable('desc')
      },
      handlerdesc1() {
        this.handlertable1('desc')
      },
      handlerasc() {
        this.handlertable('asc')
      },
      handlerasc1() {
        this.handlertable1('asc')
      },
      handlertable(order) {
        postAction(this.url.goods, { order: order })
          .then((res) => {
            console.log(res, '')
            this.tbaleData = res.data
            // this.$message({
            //   message: res.msg,
            //   type: 'success',
            // })
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlertable1(order) {
        postAction(this.url.cust, { order: order })
          .then((res) => {
            console.log(res, '')
            this.tbaleData1 = res.data
            // this.$message({
            //   message: res.msg,
            //   type: 'success',
            // })
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleronlinedata() {
        postAction(this.url.data, {})
          .then((res) => {
            console.log(res, '网销数据')
            this.Onlinedata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlertrend() {
        let option = {
          title: {
            text: '网销销量趋势图',
            left: 'center',
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '订单数量',
              data: [],
              type: 'bar',
              label: {
                show: true,
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    var colorList = ['#5d9cf9']
                    return colorList
                  },
                },
              },
            },
            {
              name: '订单金额',
              data: [],
              type: 'line',
              itemStyle: {
                normal: {
                  color: function (params) {
                    var colorList = ['#0de5ec']
                    return colorList
                  },
                  lineStyle: {
                    color: '#0de5ec', //改变折线颜色
                  },
                  label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}',
                    fontSize: 15,
                  },
                },
              },
            },
          ],
        }
        var date = new Date().getTime()
        var day = date - 1000 * 3600 * 24 * 30
        postAction(this.url.trend, { start_time: day, end_time: date })
          .then((res) => {
            console.log(res, '')
            option.xAxis.data = res.data
              .map((list) => {
                return list.create_at
              })
              .reverse()
            option.series[0].data = res.data
              .map((item) => {
                return item.count
              })
              .reverse()
            option.series[1].data = res.data
              .map((item) => {
                return item.amount
              })
              .reverse()
            console.log(option, '数据')
            this.myChartOne = this.$echarts.init(
              document.getElementById('myechart')
            )
            this.myChartOne.setOption(option)
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .grid-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
  .data {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    text-align: center;
  }
  .data p {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 15px;
  }
  .Rank {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
</style>
