var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c("div", { staticClass: "grid-content" }, [
              _c("h3", [_vm._v("网销数据")]),
              _c("div", { staticClass: "data" }, [
                _c("p", [
                  _c("span", [
                    _vm._v("新开通网销：" + _vm._s(_vm.Onlinedata.new_count)),
                  ]),
                  _c("span", [
                    _vm._v(
                      "网销订单数量：" + _vm._s(_vm.Onlinedata.order_count)
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      "开通网销客户量：" + _vm._s(_vm.Onlinedata.all_count)
                    ),
                  ]),
                  _c("span", [
                    _vm._v("网销订单金额：" + _vm._s(_vm.Onlinedata.amount)),
                  ]),
                ]),
              ]),
              _c("div", {
                staticStyle: { width: "100%", height: "400px" },
                attrs: { id: "myechart" },
              }),
            ]),
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c("div", { staticClass: "grid-content" }, [
              _c("h3", [_vm._v("访问统计")]),
              _c("div"),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "div",
                            { staticClass: "Rank" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: { click: _vm.handlerdesc },
                                },
                                [_vm._v("前十名")]
                              ),
                              _c("span", [_vm._v("商品排名")]),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: { click: _vm.handlerasc },
                                },
                                [_vm._v("后十名")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              attrs: {
                                stripe: "",
                                data: _vm.tbaleData,
                                height: "500px",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  align: "center",
                                  label: "排名",
                                },
                              }),
                              _vm._l(_vm.colemd, function (list, index) {
                                return _c("el-table-column", {
                                  key: index,
                                  attrs: {
                                    label: list.label,
                                    align: list.align,
                                    prop: list.prop,
                                    width: "",
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "div",
                            { staticClass: "Rank" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: { click: _vm.handlerdesc1 },
                                },
                                [_vm._v(" 前十名 ")]
                              ),
                              _c("span", [_vm._v("终端排名")]),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: { click: _vm.handlerasc1 },
                                },
                                [_vm._v("后十名")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              attrs: {
                                stripe: "",
                                data: _vm.tbaleData1,
                                height: "500px",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  align: "center",
                                  label: "排名",
                                },
                              }),
                              _vm._l(_vm.colemd1, function (list, index) {
                                return _c("el-table-column", {
                                  key: index,
                                  attrs: {
                                    label: list.label,
                                    align: list.align,
                                    prop: list.prop,
                                    width: "",
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }